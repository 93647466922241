/* FONT FACE */
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: font-files("Roboto-Medium.woff"); }

.Sessions-oauthHeader {
  padding-top: 16px;
  font-size: 12px;
  font-weight: 600; }

.Sessions-oauthContainer {
  display: flex;
  justify-content: space-between; }
  .Sessions-oauthContainer .Sessions-oauthButton {
    margin-left: 8px; }
  .Sessions-oauthContainer .Sessions-oauthButton:first-child {
    margin-left: 0; }

.Sessions-oauthButton {
  flex: 1;
  width: 100%;
  min-width: 156px;
  max-width: 320px;
  height: 40px;
  padding-left: 52px;
  transition: all 0.15s;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #FFF;
  box-shadow: 0 0.5px rgba(0, 0, 0, 0.2);
  color: #757575;
  font-family: 'Roboto Medium', sans-serif;
  line-height: 40px;
  vertical-align: text-bottom;
  cursor: pointer; }
  .Sessions-oauthButton:hover {
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    text-decoration: none; }

.Sessions-githubButton {
  background-image: url("/assets/1.0.0-assets.133/images/layout/sessions/GitHub-Mark-32px.png");
  background-repeat: no-repeat;
  background-position: 15px 11px;
  background-size: 18px 18px; }

.Sessions-googleButton {
  background-image: url("/assets/1.0.0-assets.133/images/layout/sessions/g-normal.png");
  background-repeat: no-repeat;
  background-position: 15px 11px;
  background-size: 18px 18px; }

.Spinner {
  position: relative;
  margin: 0 auto;
  animation: load8 1.1s infinite linear;
  border-top: 2px solid #DDD;
  border-right: 2px solid #DDD;
  border-bottom: 2px solid #DDD;
  border-left: 2px solid #0090D7; }

.Spinner,
.Spinner::after {
  width: 36px;
  height: 36px;
  border-radius: 50%; }

.Spinner--formIcon,
.Spinner--formIcon::after {
  position: absolute;
  width: 12px;
  height: 12px; }

.Spinner.Spinner--center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 -18px; }

@keyframes load8 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.Sessions {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover; }

.Sessions-navy {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #162945;
  background-size: cover; }

.Sessions-centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 180px; }
  .Sessions-centered div.header, .Sessions-centered div.footer {
    text-align: center;
    width: 562px; }
  .Sessions-centered div.footer {
    margin-top: 35px; }
  .Sessions-centered h1.Sessions-title {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 31px;
    margin: 0; }
  .Sessions-centered p.Sessions-text {
    color: #b1b8c1;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin: 15px 0; }
  .Sessions-centered p.Sessions-link a {
    color: #1785fb;
    font-size: 13px;
    font-weight: 400; }
  .Sessions-centered p.Sessions-text--qrCode {
    font-size: 11px; }
  .Sessions-centered img.qr, .Sessions-centered p.Sessions-text--qrCode {
    width: 180px; }
  .Sessions-centered p.Sessions-text--sharedSecret {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    text-align: center;
    line-height: 220px; }
  .Sessions-centered .Sessions-sharedSecretContainer {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    height: 244px;
    border-radius: 4px; }
  .Sessions-centered p.Sessions-text--code {
    font-weight: 500;
    font-size: 12px; }
    .Sessions-centered p.Sessions-text--code a {
      text-decoration: underline; }

.Sessions-noMargin {
  margin: 0; }

.Sessions-content {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 40px 0; }

.Sessions-inner {
  display: flex;
  justify-content: space-between; }

.Sessions .Sessions-title {
  color: #162945;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px; }

.Sessions-navy .Sessions-title {
  color: #FFF;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px; }

.Sessions-logoContainer {
  display: block; }

.Sessions .Sessions-description {
  margin-bottom: 16px;
  color: #979EA1;
  font-size: 13px;
  font-weight: 200;
  line-height: 20px;
  text-align: center; }
  .Sessions .Sessions-description .Sessions-text--code, .Sessions .Sessions-description .Sessions-text--sharedSecret {
    color: #979EA1; }
  .Sessions .Sessions-description a {
    color: #1785FB;
    text-decoration: none; }
  .Sessions .Sessions-description a:hover {
    text-decoration: underline; }

.Sessions-navy .Sessions-description {
  margin-bottom: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  font-weight: 200;
  line-height: 20px;
  text-align: center; }
  .Sessions-navy .Sessions-description .Sessions-text--code, .Sessions-navy .Sessions-description .Sessions-text--sharedSecret {
    color: #FFF; }
  .Sessions-navy .Sessions-description a {
    color: #FFF;
    text-decoration: none; }
    .Sessions-navy .Sessions-description a:hover {
      text-decoration: underline; }

.Sessions-loggedin,
.Sessions-notloggedin,
.Sessions-toggle {
  display: none; }
  .Sessions-loggedin.is-active,
  .Sessions-notloggedin.is-active,
  .Sessions-toggle.is-active {
    display: block; }

.users.create .Sessions-notloggedin {
  display: block; }

.users.create .Sessions-loggedin {
  display: none; }

.Sessions-left {
  display: flex;
  flex-direction: column;
  width: 320px; }

.Sessions-leftInner {
  width: 100%;
  margin: auto; }

.Sessions-right {
  width: 560px; }

.Sessions-field {
  position: relative;
  width: 100%;
  margin-bottom: 5px;
  float: left; }
  .Sessions-field:last-child {
    margin-bottom: 0; }

.Sessions-input {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #EEE;
  outline: none;
  background: #FFF;
  color: #162746;
  font-size: 14px;
  font-weight: 300; }
  .Sessions-input::-webkit-input-placeholder {
    color: #B1B8C1; }
  .Sessions-input:-moz-placeholder {
    color: #B1B8C1; }
  .Sessions-input::-moz-placeholder {
    color: #B1B8C1; }
  .Sessions-input:-ms-input-placeholder {
    color: #B1B8C1; }
  .Sessions-input:focus {
    border: 1px solid #1785FB; }
  .Sessions-input.inputWithError {
    border-color: #FFF; }
    .Sessions-input.inputWithError:focus {
      border: 1px solid #1785FB; }

.Sessions-navy-input {
  width: 100%;
  height: 48px;
  padding: 10px;
  border: 1px solid #0E1C2E;
  outline: none;
  background: #0E1C2E;
  color: #FFF;
  font-size: 14px;
  font-weight: 300; }
  .Sessions-navy-input::-webkit-input-placeholder {
    color: #B1B8C1; }
  .Sessions-navy-input:-moz-placeholder {
    color: #B1B8C1; }
  .Sessions-navy-input::-moz-placeholder {
    color: #B1B8C1; }
  .Sessions-navy-input:-ms-input-placeholder {
    color: #B1B8C1; }
  .Sessions-navy-input:focus {
    border: 1px solid #1785FB; }
  .Sessions-navy-input.inputWithError {
    border-color: #0E1C2E; }
    .Sessions-navy-input.inputWithError:focus {
      border: 1px solid #1785FB; }

.Sessions-input.topBorderRadius,
.Sessions-navy-input.topBorderRadius {
  border-radius: 4px 4px 0 0; }

.Sessions-input.bottomBorderRadius,
.Sessions-navy-input.bottomBorderRadius {
  border-radius: 0 0 4px 4px; }

.Sessions-input.withBorderRadius,
.Sessions-navy-input.withBorderRadius {
  border-radius: 4px; }

.Sessions-button:hover .Loading-itemInner, .Sessions-button:active .Loading-itemInner {
  background: #8EB83F; }

.Sessions-submitButton {
  width: 100%;
  height: 48px;
  margin: 2px 0 7px;
  border-radius: 4px;
  background-color: #60C556;
  color: #FFF;
  font-size: 12px;
  text-transform: uppercase; }
  .Sessions-submitButton:hover {
    background-color: #73C86B; }

.Sessions-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 200; }
  .Sessions-text a {
    color: #1785FB;
    text-decoration: none; }
    .Sessions-text a:hover {
      text-decoration: underline; }

.Sessions-navy .Sessions-text a {
  color: #FFF; }

.Sessions .Sessions-inputLinkAside {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  margin-right: 10px;
  color: #1785FB;
  font-size: 14px;
  font-weight: 200;
  line-height: 50px; }
  .Sessions .Sessions-inputLinkAside a {
    color: #1785FB; }

.Sessions-navy .Sessions-inputLinkAside {
  position: absolute;
  top: 0;
  right: 0;
  height: 48px;
  margin-right: 10px;
  color: #FFF;
  font-size: 14px;
  font-weight: 200;
  line-height: 50px; }
  .Sessions-navy .Sessions-inputLinkAside a {
    color: #FFF; }

.Sessions-text--forgotButton {
  line-height: 41px; }

.Sessions-text--footer {
  margin-bottom: 16px;
  padding: 0 20px;
  color: #979EA1; }
  .Sessions-text--footer:last-child {
    margin-bottom: 0; }

.Sessions-fieldError {
  position: absolute;
  top: 14px;
  right: -32px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #F24440;
  color: #FFF;
  font-weight: 400;
  line-height: 22px;
  text-align: center; }
  .Sessions-fieldError:hover {
    cursor: pointer; }

.Loading-item {
  display: inline-block;
  position: relative;
  width: 8px;
  height: 8px;
  margin-left: 1px;
  list-style-type: none; }
  .Loading-item:first-child {
    margin-left: 0; }

.Loading-itemInner {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  -webkit-animation-name: loading;
  animation-name: loading;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 50%;
  opacity: 0.2;
  background: #FFF; }

.Loading-itemInner--01 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

.Loading-itemInner--02 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.Loading-itemInner--03 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s; }

.tipsy {
  font-size: 13px; }

.Sessions-referrer {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 60px;
  margin-top: -30px;
  margin-left: -150px;
  border-radius: 4px;
  background: #FFF;
  text-align: center; }
  .Sessions-referrer picture,
  .Sessions-referrer img {
    display: inline-block;
    width: 100px;
    height: 50px;
    vertical-align: middle; }

.Sessions-referrerItem {
  display: inline-block;
  width: 100px;
  height: 50px; }

.Sessions-footer {
  margin-bottom: 32px; }

@media (max-width: 960px) {
  .Sessions-inner {
    justify-content: center; }
  .Sessions-right {
    display: none; } }

@media (max-width: 425px) {
  .Sessions-fieldError {
    display: none; }
  .Sessions-input.inputWithError {
    border-color: #F24440; } }

@media (max-width: 960px) {
  .Sessions-inner {
    justify-content: center; }
  .Sessions-right {
    display: none; } }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    opacity: 0.2; }
  50% {
    -webkit-transform: scale3d(1.4, 1.4, 1);
    opacity: 0.6; }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    opacity: 0.2; } }

@keyframes loading {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 0.2; }
  50% {
    transform: scale3d(1.4, 1.4, 1);
    opacity: 0.6; }
  100% {
    transform: scale3d(1, 1, 1);
    opacity: 0.2; } }
